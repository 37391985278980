@import "../../styles/_colors";

.pageContent {
  margin: 0;
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: justify;

  .title {
    display: block;
    font-size: 32px;
    padding-bottom: 1em;
  }

  .content {
  }
}
