$purple: #483fc1;
$black: #000000;
$dark-gray: #333333;
$medium-gray: #565656;
$gray: #979797;
$light-gray: #BBBBBB;
$snow: #F9F9F9;
$white: #FFFFFF;

$white-fadded-1: fade-out($white, 0.1);
$white-fadded-2: fade-out($white, 0.2);
$white-fadded-3: fade-out($white, 0.3);

$primary-color: $purple;
$primary-color-light: lighten($primary-color, 10%);
$primary-color-dark: darken($primary-color, 10%);
$primary-color-fadded: fade-out($primary-color-light, 0.9);
$primary-color-fadded-alittle: fade-out($primary-color-light, 0.1);
